import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Encourage supportive relationships';
export const goals = [
  {
    id: 'supportive-relationships-goal1',
    title:
      <>- Create an opportunity for your child to initiate a social interaction in the coming week. (See <Link href="/pka-supportive-relationships/pka-social-opportunities"> Provide your child opportunities to interact with others and support their social understanding</Link>).</>,
    content: '',
  },
  {
    id: 'supportive-relationships-goal2',
    title:
      <>- Pick a behaviour related to caring, sharing and cooperation that you want your child to learn, and show them by example how to do it. (See <Link href="/pka-supportive-relationships/pka-sharing-cooperation">Sharing and cooperation</Link>).</>,
    content: '',
  },
  {
    id: 'supportive-relationships-goal3',
    title: <>- If your child has ongoing conflicts with others, use CPR techniques to coach them to resolve the conflict. (See <Link href="/pka-supportive-relationships/pka-conflict-resolution-coach">Be a conflict-resolution coach</Link>).</>,
    content: '',
  },
];
