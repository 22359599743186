import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Be involved and encourage autonomy';
export const goals = [
  {
    id: 'autonomy-goal1',
    title:<>- Think of a way your child could be more independent in their daily routines. Discuss this with them and encourage them to take more responsibility for these tasks. (See <Link href="/pka-autonomy/pka-responsibility">Give your child responsibility</Link>).</>,
    content: '',
  },
  {
    id: 'autonomy-goal2',
    title:<>- Familiarise yourself with all the social media sites and games your child is involved in. Keep an eye on and show interest in their online activity during the coming week. (See <Link href="/pka-autonomy/pka-online-life">Make sure that you fully understand the technology your child is using</Link>).</>,
    content: '',
  },
  {
    id: 'autonomy-goal3',
    title: <>- Think of two activities that might involve some risk but are appropriate for your child's age and maturity level, and encourage your child to try these activities. (See <Link href="/pka-autonomy/pka-risks">Let your child have a go at managing risks that are developmentally appropriate</Link>).</>,
    content: '',
  },
  {
    id: 'autonomy-goal4',
    title:<>- Discuss with your child an extra-curricular activity that they might be interested in doing and provide them with the opportunity and resources to do. (See <Link href="/pka-autonomy/pka-try-new-things">Encourage your child to try new things</Link>).</>,
    content: '',
  },
];
