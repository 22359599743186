import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Encourage good health habits';
export const goals = [
  {
    id: 'health-habits-goal1',
    title:<>- Introduce one good eating habit or mealtime routine to your child and keep track of their progress for a week. (See <Link href="/pka-health-habits/pka-balanced-diet">Encourage a nutritious, balanced diet</Link>).</>,
    content: '',
  },
  {
    id: 'health-habits-goal2',
    title:<>- Create a physical activity schedule for your child for the coming week and put it into action. (See <Link href="/pka-health-habits/pka-physical-activity">Encourage healthy habits around physical activity</Link>).</>,
    content: '',
  },
  {
    id: 'health-habits-goal3',
    title: <>- Introduce one healthy sleep habit to your child and practise enforcing it every night for a week. (See <Link href="/pka-health-habits/pka-sleep-habits">Encourage healthy sleep habits</Link>).</>,
    content: '',
  },
  {
    id: 'health-habits-goal4',
    title:<>- Come up with rules and consequences around screen time as a family and make sure everyone sticks to them for the coming week. (See <Link href="/pka-health-habits/pka-screentime">Encourage healthy habits around screen time</Link>).</>,
    content: '',
  },
];
