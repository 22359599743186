import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Manage tough emotions';
export const goals = [
  {
    id: 'tough-emotions-goal1',
    title:
      <>- If you notice something is bothering your child, use strategies to support them. (See <Link href="/pka-tough-emotions/pka-support-your-child">Support your child if something is bothering them</Link>).</>,
    content: '',
  },
  {
    id: 'tough-emotions-goal2',
    title:
      <>- Find out whether your child has enough time to relax and recharge their batteries. If they have too much going on, discuss with them ways to give them more free time. (See <Link href="/pka-tough-emotions/pka-childs-schedule">Make sure your child is not overwhelmed by their schedule</Link>).</>,
    content: '',
  },
  {
    id: 'tough-emotions-goal3',
    title: <>- Teach your child a relaxation strategy and practise it with them this week. (See <Link href="/pka-tough-emotions/pka-manage-anxiety">Help your child manage their anxiety so it doesn’t become a problem</Link>).</>,
    content: '',
  },
  {
    id: 'tough-emotions-goal4',
    title:
      <>- Encourage your child to face one of their fears by offering rewards and praise. (See <Link href="/pka-tough-emotions/pka-manage-anxiety">Help your child manage their anxiety so it doesn’t become a problem</Link>).</>,
    content: '',
  },
];
