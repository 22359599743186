import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Manage conflict in the home';
export const goals = [
  {
    id: 'conflict-goal1',
    title: (
      <>
        - Practise making comments about a family member's actions, rather than making negative remarks about them as a
        person. (See{' '}
        <Link href="/pka-conflict/pka-respond-without-judgement">
          Respond to your child’s behaviour without judging
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'conflict-goal2',
    title: (
      <>
        - Monitor teasing, put downs or name-calling within your family. Step in and establish rules about respectful
        communication if needed. (See{' '}
        <Link href="/pka-conflict/pka-manage-conflict-family">Manage conflicts between family members</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'conflict-goal3',
    title: (
      <>
        - Practice making an important decision as a family. Encourage everyone to contribute to the discussion. (See{' '}
        <Link href="/pka-conflict/pka-manage-conflict-family">Manage conflicts between family members</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'conflict-goal4',
    title: (
      <>
        - Think about how your child is affected by the way you manage conflict with your partner. Discuss with your
        partner how you can improve this. (See{' '}
        <Link href="/pka-conflict/pka-manage-conflict-partner">Manage conflict with your partner</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'conflict-goal5',
    title: (
      <>
        - Use skills you have learned in this module to be a role model for your child on how to manage angry feelings.
        (See <Link href="/pka-conflict/pka-role-model-calm">Be a good role model for keeping calm</Link>).
      </>
    ),
    content: '',
  },
];
