import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = "Guide your child's behaviours";
export const goals = [
  {
    id: 'behaviour-goal1',
    title:<>- Identify one of your child’s behaviours that you find challenging. Consider the purpose of the behaviour, and how you can intervene to support your child’s needs. (See <Link href="/pka-behaviour/pka-abcs-behaviour">The ABCs of behaviour</Link>, <Link href="/pka-behaviour/pka-behaviours-of-concern">Understanding specific behaviours of concern</Link> and <Link href="/pka-behaviour/pka-abc-action-plan">Developing an ABC action plan)</Link>.</>,
    content: '',
  },
  {
    id: 'behaviour-goal2',
    title:<>- Think about how you can help your child plan their day and rest time so that they won’t run out of energy. (See <Link href="/pka-behaviour/pka-preventive-strategies">Preventive strategies through antecedents)</Link>.</>,
    content: '',
  },
  {
    id: 'behaviour-goal3',
    title: <>- Identify a situation where you can use appropriate consequences to encourage your child to repeat a positive behaviour. (See <Link href="/pka-behaviour/pka-guiding-behaviour">Guiding behaviour through consequences)</Link>.</>,
    content: '',
  },
  {
    id: 'behaviour-goal4',
    title:<>- Keep a record of your child’s behaviours in the coming week using the ABC chart, and identify your child’s cues or distress signals before a meltdown or shutdown. Think of what you can do to keep your child and family safe during these times. (See <Link href="/pka-behaviour/pka-rumbling-stage">Intervening at the ‘rumbling’ stage</Link>, <Link href="/pka-behaviour/pka-surviving-the-storm">Surviving the storm</Link>, <Link href="/pka-behaviour/pka-recovering-after-storm">Recovering after the storm</Link> and <Link href="/pka-behaviour/pka-crisis">If it turns into a crisis)</Link>.</>,
    content: '',
  },
];
