import { goals as goals1, topicTitle as title1 } from './goals/pka-affection-acceptance';
import { goals as goals2, topicTitle as title2 } from './goals/pka-behaviour';
import { goals as goals3, topicTitle as title3 } from './goals/pka-autonomy';
import { goals as goals4, topicTitle as title4 } from './goals/pka-everyday-emotions';
import { goals as goals5, topicTitle as title5 } from './goals/pka-tough-emotions';
import { goals as goals6, topicTitle as title6 } from './goals/pka-yourself';
import { goals as goals7, topicTitle as title7 } from './goals/pka-connect';
import { goals as goals8, topicTitle as title8 } from './goals/pka-supportive-relationships';
import { goals as goals9, topicTitle as title9 } from './goals/pka-family-rules';
import { goals as goals10, topicTitle as title10 } from './goals/pka-health-habits';
import { goals as goals11, topicTitle as title11 } from './goals/pka-conflict';
import { goals as goals12, topicTitle as title12 } from './goals/pka-goals';
import { goals as goals13, topicTitle as title13 } from './goals/pka-seek-help';
import { goals as goals14, topicTitle as title14 } from './goals/pka-fundamentals';
import { goals as goals15, topicTitle as title15 } from './goals/pka-sensory-needs';

export const goals = [
  { title: title1, goals: goals1 },
  { title: title2, goals: goals2 },
  { title: title3, goals: goals3 },
  { title: title4, goals: goals4 },
  { title: title5, goals: goals5 },
  { title: title6, goals: goals6 },
  { title: title7, goals: goals7 },
  { title: title8, goals: goals8 },
  { title: title9, goals: goals9 },
  { title: title10, goals: goals10 },
  { title: title11, goals: goals11 },
  { title: title12, goals: goals12 },
  { title: title13, goals: goals13 },
  { title: title14, goals: goals14 },
  { title: title15, goals: goals15 },
];
