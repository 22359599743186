import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = "Support your child's sensory needs";
export const goals = [
  {
    id: 'sensory-needs-goal1',
    title: <>- Think about the size of your child’s sensory cup for each of their senses. Consider how you can support your child to prevent any of the cups from getting too full (e.g. feel overwhelmed) or too empty (e.g. feel under-stimulated). (See <Link href="/pka-sensory-needs/pka-sensory-processing">Understanding sensory processing</Link> and <Link href="/pka-sensory-needs/pka-sensory-challenges">Solutions for everyday sensory challenges</Link>)</>,
    content: '',
  },
  {
    id: 'sensory-needs-goal2',
        title: <>- Build your child’s sensory toolkit by keeping a log of how your child responds to different activities and adding those they respond well to the toolkit. (See <Link href="/pka-sensory-needs/pka-sensory-challenges">Solutions for everyday sensory challenges</Link>).</>,
    content: '',
  },
  {
    id: 'sensory-needs-goal3',
    title: <>- Discuss with your child’s occupational therapist how you can better support your child’s sensory needs. (See <Link href="/pka-sensory-needs/pka-sensory-challenges">Solutions for everyday sensory challenges</Link>).</>,
    content: '',
  },
  {
    id: 'sensory-needs-goal4',
    title:
      <>- Watch your child for a week and record whether your child’s repetitive behaviours or stims are helping them to become calmer and focused, or if they are making your child more worked up and distressed. (See <Link href="/pka-sensory-needs/pka-repetitive-behaviours">Repetitive Behaviours and Stimming</Link>).</>,
    content: '',
  },
]
