import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Show affection and acceptance';
export const goals = [
  {
    id: 'affection-acceptance-goal1',
    title: (
      <>
        - Respond to your child in a warm, loving and respectful way this week. (See{' '}
        <Link href="/pka-affection-acceptance/pka-respond-consistently">
          Respond to your child consistently in a warm, loving, supportive, and respectful way
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'affection-acceptance-goal2',
    title: (
      <>
        - Show your child affection and say ‘I love you’ or a similar phrase three times this week. (See{' '}
        <Link href="/pka-affection-acceptance/pka-love-them">Regularly tell your child you love them</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'affection-acceptance-goal3',
    title: (
      <>
        - Find two opportunities to use encouragement with your child this week. (See{' '}
        <Link href="/pka-affection-acceptance/pka-praise-encouragement">
          Provide regular praise and encouragement and identify their strengths
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'affection-acceptance-goal4',
    title: (
      <>
        - Praise your child twice this week. (See{' '}
        <Link href="/pka-affection-acceptance/pka-praise-encouragement">
          Provide regular praise and encouragement and identify their strengths
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'affection-acceptance-goal5',
    title: (
      <>
        - Think about how your hopes and dreams for your child match with their personality, abilities and interests.
        (See <Link href="/pka-affection-acceptance/pka-accept-your-child">Accept your child for who they are</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'affection-acceptance-goal6',
    title: (
      <>
        - Think about the similarities and differences between your child and yourself/their siblings. Try to find out
        whether this affects the way you react to them (See{' '}
        <Link href="/pka-affection-acceptance/pka-accept-your-child">Accept your child for who they are</Link>).
      </>
    ),
    content: '',
  },
];
