import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Manage everyday emotions';
export const goals = [
  {
    id: 'everyday-emotions-goal1',
    title: (
      <>
        - Use one of the strategies in{' '}
        <Link href="/pka-everyday-emotions/pka-understand-emotions">Help your child understand their emotions</Link> to
        help your child express their emotions better.
      </>
    ),
    content: '',
  },
  {
    id: 'everyday-emotions-goal2',
    title: (
      <>
        - Practise the strategies in{' '}
        <Link href="/pka-everyday-emotions/pka-unhelpful-thoughts">
          Help your child to identify and change unhelpful thoughts
        </Link>{' '}
        to help your child change their unhelpful thoughts.
      </>
    ),
    content: '',
  },
  {
    id: 'everyday-emotions-goal3',
    title: (
      <>
        - Find out what your child's self-talk is like, and work with them to come up with two "cheerleading statements"
        they can use. (See{' '}
        <Link href="/pka-everyday-emotions/pka-cheerleader">Encourage your child to be their own cheerleader</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'everyday-emotions-goal4',
    title: (
      <>
        - Think about how to respond to your child's emotions and change your style of responding to a more supportive
        one. (See{' '}
        <Link href="/pka-everyday-emotions/pka-patience-empathy">Show patience and empathy during times of change</Link>
        ).
      </>
    ),
    content: '',
  },
];
