import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Look after yourself';
export const goals = [
  {
    id: 'yourself-goal1',
    title: (
      <>
        - Identify a situation that causes moderate-to-high stress in your life, and apply the 6 problem-solving steps
        to solve this problem. (See <Link href="/pka-yourself/pka-problem-solving">Problem-solving</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'yourself-goal2',
    title: (
      <>
        - Practise one daily self-care activity and acknowledge that you have done something for yourself each day this
        week. (See <Link href="/pka-yourself/pka-coping-strategies">Take a few moments</Link> and{' '}
        <Link href="/pka-yourself/pka-self-care-plan">Set up your self-care plan</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'yourself-goal3',
    title: (
      <>
        - Develop a recovery plan to manage your burnout. (See{' '}
        <Link href="/pka-yourself/pka-carer-burnout">Strategies for managing overwhelm and burn out</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'yourself-goal4',
    title: (
      <>
        - Connect with a supportive family member or friend who cares about your autistic child. (See{' '}
        <Link href="/pka-yourself/pka-fighting-isolation">Fighting Isolation</Link>).
      </>
    ),
    content: '',
  },
];
