import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Connect';
export const goals = [
  {
    id: 'connect-goal1',
    title: (
      <>
        - Practise letting your child know that you are available to talk. (See{' '}
        <Link href="/pka-connect/pka-care">
          Let your child know that you are care about them and will always be there for them
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'connect-goal2',
    title: (
      <>
        - Practise telling your child that you’ll talk to them later without putting them off. (See{' '}
        <Link href="/pka-connect/pka-available-to-talk">
          Make sure you’re available if your child wants to talk to you
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'connect-goal3',
    title: (
      <>
        - Plan a few more one-on-one activities with your child. (See{' '}
        <Link href="/pka-connect/pka-one-on-one-time">Spend one-on-one time with your child on a regular basis</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'connect-goal4',
    title: (
      <>
        - Use some of the verbal or nonverbal strategies in this module to connect with your child. (See{' '}
        <Link href="/pka-connect/pka-non-verbal-communication">
          Connect with your child using nonverbal means of communication
        </Link>{' '}
        and{' '}
        <Link href="/pka-connect/pka-available-to-talk">
          Make sure you’re available if your child wants to talk to you
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'connect-goal5',
    title: (
      <>
        - Practise listening patiently to your child and giving them your full attention when they talk to you. (See{' '}
        <Link href="/pka-connect/pka-how-to-talk">How to talk and listen to your child effectively</Link>).
      </>
    ),
    content: '',
  },
];
