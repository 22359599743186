import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Establish family rules and consequences';
export const goals = [
  {
    id: 'family-rules-goal1',
    title: (
      <>
        - Come up with two family rules and consequences with your family this week. (See{' '}
        <Link href="/pka-family-rules/pka-work-together">Work together to establish rules and consequences</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'family-rules-goal2',
    title: (
      <>
        - Use one of the tools in{' '}
        <Link href="/pka-family-rules/pka-rules-are-followed">
          Make sure rules are followed through with consequences
        </Link>{' '}
        to follow through with consequences.
      </>
    ),
    content: '',
  },
  {
    id: 'connect-goal3',
    title: (
      <>
        - Discuss with your partner how you can present a united message about family rules and consequences. (See{' '}
        <Link href="/pka-family-rules/pka-united-message">
          Provide a united message about family rules and discipline
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'family-rules-goal4',
    title: (
      <>
        - Review your family rules and work together with your family to make any changes that need to be made. (See{' '}
        <Link href="/pka-family-rules/pka-changing-the-rules">Do you need to change the rules?</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'family-rules-goal5',
    title: (
      <>
        - Identify one rule or law that you’re not following and commit to being a better role model in the coming week.
        (See <Link href="/pka-family-rules/pka-role-model">Be a good role model for following the rules</Link>).
      </>
    ),
    content: '',
  },
];
