import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Seek help';
export const goals = [
  {
    id: 'seek-help-goal1',
    title: <>- Think about what factors in your child's life might increase their chances of developing depression or an anxiety disorder. Find out what you might be able to do to reduce the impact of these factors on your child. (See <Link href="/pka-seek-help/pka-risk-anxiety">What factors increase the risk of an anxiety disorder in children?</Link> and <Link href="/pka-seek-help/pka-risk-depression">What factors increase the risk of depression in children?</Link>)</>,
    content: '',
  },
  {
    id: 'seek-help-goal2',
        title: <>- If you are concerned that your child is showing signs of depression or an anxiety disorder, talk with them about it this week and offer to arrange for them to speak to a professional (e.g. counsellor, GP). (See <Link href="/pka-seek-help/pka-not-ok">If things aren’t okay…</Link>).</>,
    content: '',
  },
  {
    id: 'seek-help-goal3',
    title: <>- Reflect on whether your own mental health is affecting your behaviour towards your child, and if so, seek professional help. (See <Link href="/pka-seek-help/pka-professional-help">Seek professional help for yourself when needed</Link>).</>,
    content: '',
  },
  {
    id: 'seek-help-goal4',
    title:
      <>- Take time to find out more about the online resources provided in the <Link href="/resources">Useful Resources</Link>.</>,
    content: '',
  },
];
