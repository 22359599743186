import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Support goal setting and problem solving';
export const goals = [
  {
    id: 'goals-goal1',
    title: (
      <>
        - Help your child set a SMART goal this week. (See{' '}
        <Link href="/pka-goals/pka-set-up-for-success">
          Set your child up for success by making your child’s goals realistic
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'goals-goal2',
    title: (
      <>
        - Reflect on whether you rush in to solve your child's problems, and think about how you can encourage greater
        independence in your child when it comes to solving their problems. (See{' '}
        <Link href="/pka-goals/pka-solve-own-problems">Help your child solve their own problems</Link>).
      </>
    ),
    content: '',
  },
  {
    id: 'goals-goal3',
    title: (
      <>
        - Celebrate one of your child's achievements this week or provide encouragement to keep them working towards
        their goals. (See <Link href="/pka-goals/pka-celebrate-child-success">Celebrate your child’s successes</Link>{' '}
        and{' '}
        <Link href="/pka-goals/pka-encourage-your-child">
          Encourage your child even if they fall short of their goals
        </Link>
        ).
      </>
    ),
    content: '',
  },
  {
    id: 'goals-goal4',
    title: (
      <>
        - Try to find out if your child has any problems at school and help them find ways to solve these problems. (See{' '}
        <Link href="/pka-goals/pka-deal-with-school-problems">Help your child deal with problems at school</Link>).
      </>
    ),
    content: '',
  },
];
